import './username-settings.css';

const UsernameSettings = ({ self, onClick }) => {
	return (
		<div className="username-settings">
			<button onClick={onClick} style={{ '--color': self.color }}>
				{`You: ${self.name}`}
			</button>
		</div>
	);
};

export default UsernameSettings;
