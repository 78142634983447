export const commands = {
	USER_NAME: 'name',
	USER_COLOR: 'color',
	CLAIM_ADMIN: 'admin',
	ADMIN_SET_CLIENT_PASSWORD: 'setpassword',
	ADMIN_NO_CLIENT_PASSWORD: 'nopassword',
	ADMIN_SET_STREAM_TITLE: 'streamtitle',
	ADMIN_SET_STREAM_URL: 'streamurl',
	ADMIN_START_STREAM: 'streamstart',
	ADMIN_STOP_STREAM: 'streamstop',
	ADMIN_CONFIG_STREAM: 'streamconfig',
	CLEAR_CHAT: 'clear',
};
