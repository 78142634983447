import './video.css';

import { useEffect, useRef, useState } from 'react';

import WatchList from './watch-list';

import SkullIcon2 from '../skull-icon-2.svg';

const Video = ({ selfId, isLive, streamTitle, streamURL, users }) => {
	const ivsPlayer = useRef(null);
	const [isShowingListenBlocker, setIsShowingListenBlocker] = useState(false);

	const isShowingListenBlockerRef = useRef(isShowingListenBlocker);
	const setIsShowingListenBlockerViaRef = (isShowingListenBlocker) => {
		isShowingListenBlockerRef.current = isShowingListenBlocker;
		setIsShowingListenBlocker(isShowingListenBlocker);
	};

	useEffect(() => {
		if (window.awsVideoLoaded || !isLive || !streamURL || !streamTitle) {
			return;
		}

		console.log('AWS loading video');

		window.awsVideoLoaded = true;

		const newPlayer = window.IVSPlayer.create();
		ivsPlayer.current = newPlayer;
		console.log('AWS player', newPlayer);
		newPlayer.attachHTMLVideoElement(document.getElementById('ivs-video'));
		// const listener = (cue) => {
		// 	console.log('CUE', cue);
		// 	// const position = newPlayer.getPosition();
		// 	// messages.current = [
		// 	// 	...(messages.current || []),
		// 	// 	{
		// 	// 		position,
		// 	// 		message: cue.text,
		// 	// 		time: Date.now(),
		// 	// 	},
		// 	// ];
		// 	// setLastMessageTime(performance.now());
		// };
		// setCueListener(listener);
		newPlayer.addEventListener(
			window.IVSPlayer.PlayerEventType.AUDIO_BLOCKED,
			() => {
				setIsShowingListenBlockerViaRef(true);
			}
		);

		newPlayer.addEventListener(
			window.IVSPlayer.PlayerEventType.ERROR,
			(err) => {
				console.error('error');
				console.log(err);
			}
		);

		newPlayer.addEventListener(
			window.IVSPlayer.PlayerEventType.INITIALIZED,
			() => {
				console.log('AWS INITTTT');
			}
		);

		// newPlayer.addEventListener(
		// 	window.IVSPlayer.PlayerEventType.TEXT_METADATA_CUE,
		// 	listener
		// );
		newPlayer.setAutoplay(true);

		// document.addEventListener('mousemove', () => {
		// 	newPlayer.setVolume(1);
		// });
		//AWS TEST STREAM:

		newPlayer.load(streamURL);
		// newPlayer.load(
		// 	'https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.xhP3ExfcX8ON.m3u8'
		// );

		// newPlayer.load(
		// 	'https://832a28b92cc3.us-east-1.playback.live-video.net/api/video/v1/us-east-1.949219934163.channel.rSoCsggo1R0b.m3u8'
		// );

		// TEST STREAM:
		// /streamconfig {"title":"Test Title", "adminpw":"password", "clientpw":false, "live": true, "url":"https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.xhP3ExfcX8ON.m3u8"}

		// REAL STREAM:
		// /streamconfig {"title":"Test Title", "adminpw":"password", "clientpw":false, "live": true, "url":"https://832a28b92cc3.us-east-1.playback.live-video.net/api/video/v1/us-east-1.949219934163.channel.rSoCsggo1R0b.m3u8"}

		// setPlayer(newPlayer);
	}, [isLive, streamURL, streamTitle]);

	const setVolumeTo100 = () => {
		document.getElementById('ivs-video').muted = false;
		document.getElementById('ivs-video').volume = 1;
		ivsPlayer.current.setVolume(1);

		setIsShowingListenBlockerViaRef(false);
	};

	return (
		<div className="video">
			<div className="about">
				<div className="title">
					<span className="now-streaming">
						{isLive ? 'Now streaming:' : 'Offline'}
					</span>
					<h1>{isLive ? streamTitle : 'Offline'}</h1>
				</div>
				<WatchList selfId={selfId} users={users} />
			</div>
			<div className="video-container">
				{isLive ? (
					<div className="live">Live</div>
				) : (
					<div
						className="video-placeholder"
						style={{ backgroundImage: `url(${SkullIcon2})` }}
					>
						Nothing's playing. Or maybe we got shutdown by the feds.
					</div>
				)}
				<video
					className={isLive ? 'is-live' : ''}
					id="ivs-video"
					// src={'https://ruffcut.video/assets/header.mp4'}
				></video>

				{isShowingListenBlockerRef.current ? (
					<div className="listen-blocker" onClick={() => setVolumeTo100()}>
						<span>Click to watch & listen...</span>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default Video;
