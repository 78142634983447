import { useEffect } from 'react';
import SkullIcon from '../skull-icon.svg';

const DialogHeader = ({ title, onCancel }) => {
	useEffect(() => {
		if (!onCancel) {
			return;
		}

		const onKey = (event) => {
			if (event.key === 'Escape') {
				onCancel();
			}
		};

		document.addEventListener('keyup', onKey);

		return () => {
			document.removeEventListener('keyup', onKey);
		};
	}, [onCancel]);

	return (
		<div className="header dialog-item">
			<img className="icon" src={SkullIcon} />
			<span>{title}</span>
			{onCancel ? (
				<button className="cancel-button" onClick={onCancel}>
					&times;
				</button>
			) : null}
		</div>
	);
};

export default DialogHeader;
