import UserName from './user-name';

const AT_USER_REGEX = /@(\w+)/g;

const Userify = ({ usersByName, children }) => {
	return children.flatMap((child) => {
		if (typeof child === 'string') {
			// const matches = child.match(AT_USER_REGEX);

			if (child.indexOf('@') === -1) {
				return child;
			}

			let tokens = [];
			let matches;
			let lastIndex = 0;
			while ((matches = AT_USER_REGEX.exec(child)) != null) {
				const text = matches[1];
				const index = matches.index;

				tokens.push(child.substring(lastIndex, index));

				const user = usersByName[text];
				if (user) {
					tokens.push(<UserName user={user} name={`@${text}`} />);
				} else {
					tokens.push(`@${text}`);
				}

				lastIndex = index + text.length + 1;
			}
			tokens.push(child.substring(lastIndex));

			return tokens.filter((s) => s);
		}

		return child;
	});
};

export default Userify;
