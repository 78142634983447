import './watch-list.css';

import UserName from './user-name';

const WatchList = ({ selfId, users }) => {
	const userIds = Object.values(users).filter(
		(user) => user.state === 'joined'
	);

	return (
		<div className="watch-list">
			<span>{`${userIds.length} Watching`}</span>
			{users ? (
				<ul>
					{userIds.map((user) => {
						const isSelf = user.id === selfId;

						return (
							<li key={user.id}>
								🙂{' '}
								<UserName
									user={user}
									name={user.name + (isSelf ? ' (You)' : '')}
								/>
							</li>
						);
					})}
				</ul>
			) : null}
		</div>
	);
};

export default WatchList;
