import './user-name.css';

const UserName = ({ user, name }) => {
	return (
		<span className="username" style={{ '--color': user.color }}>{`${
			user.isAdmin ? '👑 ' : ''
		}${name} `}</span>
	);
};

export default UserName;
