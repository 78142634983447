import './dialog-color.css';

import { useEffect, useState } from 'react';

import Button from './button';
import DialogHeader from './dialog-header';
import { userColors } from '../constants/user-colors';

const DialogColor = ({ color, onSetColor, onCancel }) => {
	const [userColor, setUserColor] = useState(color);

	useEffect(() => {
		document.getElementsByClassName('radio-color')[0].focus();
	}, []);

	return (
		<div className="dialog dialog-color">
			<DialogHeader title="Set your profile..." onCancel={onCancel} />
			<form
				className="content dialog-item"
				onSubmit={(event) => {
					event.preventDefault();
					onSetColor(userColor);
				}}
			>
				<p>Last, choose a color:</p>
				<div className="colors">
					{userColors.map((color) => (
						<input
							key={color}
							checked={userColor === color}
							className="radio-color"
							name="radio-color"
							type="radio"
							value={color}
							style={{ '--color': color }}
							onChange={(event) => setUserColor(color)}
						/>
					))}
				</div>
				<div className="buttons">
					<Button isSubmit className="main-button">
						Join the stream
					</Button>
				</div>
			</form>
		</div>
	);
};

export default DialogColor;
