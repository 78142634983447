import './App.css';

import { useState, useEffect, useRef } from 'react';

import Video from './components/video';
import LiveChat from './components/live-chat';
import UsernameSettings from './components/username-settings';
import Modal from './components/modal';
import DialogName from './components/dialog-name';
import DialogColor from './components/dialog-color';
import SkullIcon from './skull-icon.svg';
import { userStates } from './constants/user-states';
import io from 'socket.io-client';
import { parseMessage, messageTypes } from './util/parse-message';
import { commands } from './constants/commands';

let socket;

const LOCAL_STORAGE_USER_KEY = 'user';

const getSelfPropsFromLocalStorage = () => {
	try {
		const selfProps = JSON.parse(window.localStorage[LOCAL_STORAGE_USER_KEY]);

		if (!selfProps.name || !selfProps.color) {
			return null;
		}

		return {
			name: selfProps.name,
			color: selfProps.color,
		};
	} catch (e) {
		return null;
	}
};

export default function Home() {
	const [selfId, setSelfId] = useState(1);
	const [users, setUsers] = useState({});
	const [usersByName, setUsersByName] = useState({});
	const [clientPassword, setClientPassword] = useState(null);

	const [messages, setMessages] = useState([]);
	const [isLive, setIsLive] = useState(false);
	const [streamTitle, setStreamTitle] = useState('');
	const [streamURL, setStreamURL] = useState('');
	const [userState, setUserState] = useState(userStates.INIT);
	const [isInitialSetupComplete, setIsIsInitialSetupComplete] = useState(false);

	const messagesRef = useRef(messages);
	const setMessagesViaRef = (messages) => {
		messagesRef.current = messages;
		setMessages(messages);
	};

	const usersRef = useRef(users);
	const setUsersViaRef = (users) => {
		usersRef.current = users;
		setUsers(users);
	};

	const usersByNameRef = useRef(usersByName);
	const setUsersByNameViaRef = (usersByName) => {
		usersByNameRef.current = usersByName;
		setUsersByName(usersByName);
	};

	const selfIdRef = useRef(selfId);
	const setSelfIdViaRef = (selfId) => {
		selfIdRef.current = selfId;
		setSelfId(selfId);
	};

	const streamTitleRef = useRef(streamTitle);
	const setStreamTitleViaRef = (streamTitle) => {
		streamTitleRef.current = streamTitle;
		setStreamTitle(streamTitle);
	};

	const streamURLRef = useRef(streamURL);
	const setStreamURLViaRef = (streamURL) => {
		streamURLRef.current = streamURL;
		setStreamURL(streamURL);
	};

	const isLiveRef = useRef(isLive);
	const setIsLiveViaRef = (isLive) => {
		isLiveRef.current = isLive;
		setIsLive(isLive);
	};

	const clientPasswordRef = useRef(clientPassword);
	const setClientPasswordViaRef = (clientPassword) => {
		clientPasswordRef.current = clientPassword;
		setClientPassword(clientPassword);
	};

	const self = users[selfIdRef.current];

	const notify = (message) => {
		socket.emit('notify', message);
	};

	const addMessage = (message) => {
		socket.emit('addMessage', clientPasswordRef.current, message);
	};

	const clearChat = () => {
		socket.emit('clearChat');
	};

	const claimAdmin = (adminPassword) => {
		socket.emit('claimAdmin', adminPassword);
	};

	const adminSetClientPassword = (userPassword) => {
		socket.emit('adminSetClientPassword', userPassword);
	};

	const adminNoClientPassword = () => {
		socket.emit('adminNoClientPassword');
	};

	const adminSetStreamTitle = (streamTitle) => {
		socket.emit('adminSetStreamTitle', streamTitle);
	};

	const adminSetStreamURL = (streamURL) => {
		socket.emit('adminSetStreamURL', streamURL);
	};

	const adminStartStream = () => {
		socket.emit('adminStartStream');
	};

	const adminStopStream = () => {
		socket.emit('adminStopStream');
	};

	const adminConfigStream = (configJSON) => {
		socket.emit('adminConfigStream', configJSON);
	};

	const onAddUserMessage = (messageText) => {
		const message = parseMessage(messageText);

		switch (message.type) {
			case messageTypes.MESSAGE_TYPE_TEXT:
				return addMessage(messageText);

			case messageTypes.MESSAGE_TYPE_COMMAND:
				switch (message.command) {
					case commands.USER_NAME:
						return setUserName(message.commandText);

					case commands.USER_COLOR:
						return setUserColor(message.commandText);

					case commands.CLAIM_ADMIN:
						return claimAdmin(message.commandText);

					case commands.ADMIN_SET_CLIENT_PASSWORD:
						return adminSetClientPassword(message.commandText);

					case commands.ADMIN_NO_CLIENT_PASSWORD:
						return adminNoClientPassword();

					case commands.ADMIN_SET_STREAM_TITLE:
						return adminSetStreamTitle(message.commandText);

					case commands.ADMIN_SET_STREAM_URL:
						return adminSetStreamURL(message.commandText);

					case commands.ADMIN_START_STREAM:
						return adminStartStream();

					case commands.ADMIN_STOP_STREAM:
						return adminStopStream();

					case commands.ADMIN_CONFIG_STREAM:
						return adminConfigStream(message.commandText);

					case commands.CLEAR_CHAT:
						return clearChat();

					default:
						return;
				}

			case messageTypes.MESSAGE_TYPE_UNRECOGNIZED:
				return notify(`Unrecognized command: "${message.text}"`);

			default:
				return;
		}
	};

	const setUserName = (newName) => {
		socket.emit('updateSelf', clientPasswordRef.current, 'name', newName);
	};

	const setUserColor = (newColor) => {
		socket.emit('updateSelf', clientPasswordRef.current, 'color', newColor);
	};

	const onSetName = (name) => {
		setUserName(name);
		setUserState(userStates.SET_COLOR);
	};

	const onSetColor = (color) => {
		setUserColor(color);
		setUserState(userStates.WATCH);
	};

	const getDialog = (userState) => {
		switch (userState) {
			case userStates.SET_NAME:
				return <DialogName name={self.name} onSetName={onSetName} onCancel={isInitialSetupComplete ? () => setUserState(userStates.WATCH) : null} />;

			case userStates.SET_COLOR:
				return <DialogColor color={self.color} onSetColor={onSetColor} onCancel={isInitialSetupComplete ? () => setUserState(userStates.WATCH) : null} />;

			default:
				return;
		}
	};

	const join = () => {
		setIsIsInitialSetupComplete(true);
		socket.emit('join', clientPasswordRef.current);
	};

	const initUsers = (users) => {
		setUsersViaRef(users);

		let usersByName = {};
		Object.keys(users).forEach((userId) => {
			const user = users[userId];
			usersByName[user.name] = user;
		});
		setUsersByNameViaRef(usersByName);
	};

	const updateUsers = (user) => {
		initUsers({ ...usersRef.current, [user.id]: user });
	};

	const writeSelfToLocalStorage = (user) => {
		if (user.id !== selfIdRef.current) {
			return;
		}

		window.localStorage[LOCAL_STORAGE_USER_KEY] = JSON.stringify({
			name: user.name,
			color: user.color,
		});
	};

	const updateStream = ({ title, url, isLive }) => {
		setStreamTitleViaRef(title);
		setStreamURLViaRef(url);
		setIsLiveViaRef(isLive);
	};

	useEffect(() => {
		if (!isInitialSetupComplete && userState === userStates.WATCH) {
			join();
		}
	}, [userState, isInitialSetupComplete]);

	useEffect(() => {
		// socket = io('http://10.0.0.200:3001');
		socket = io();
		// socket = io();

		socket.emit('isPasswordRequired', (isPasswordRequired) => {
			console.log('isPasswordRequired', isPasswordRequired);
			if (isPasswordRequired) {
				setClientPasswordViaRef(prompt('This stream requires a password...'));
			}

			socket.emit('init', clientPasswordRef.current, (info) => {
				if (!info) {
					alert('Sorry, that password is invalid.');
					return;
				}

				const { newUser, users, stream } = info;

				setSelfIdViaRef(newUser.id);
				initUsers(users);

				const storedUserProps = getSelfPropsFromLocalStorage();

				if (storedUserProps) {
					onSetName(storedUserProps.name);
					onSetColor(storedUserProps.color);
				} else {
					setUserState(userStates.SET_NAME);
				}

				updateStream(stream);
			});
		});

		socket.on('userJoined', (user) => {
			writeSelfToLocalStorage(user);
			// setUsersViaRef({ ...usersRef.current, [user.id]: user });
			updateUsers(user);
		});

		socket.on('userUpdated', (user) => {
			writeSelfToLocalStorage(user);
			// setUsersViaRef({ ...usersRef.current, [user.id]: user });
			updateUsers(user);
		});

		socket.on('newMessage', (message) => {
			setMessagesViaRef([...messagesRef.current, message]);
		});

		socket.on('userDisconnected', (user) => {
			// setUsersViaRef({ ...usersRef.current, [user.id]: user });
			updateUsers(user);
		});

		socket.on('streamUpdated', (streamDetails) => {
			updateStream(streamDetails);
		});

		socket.on('promptForPassword', () => {
			const password = prompt('This stream now requires a password...');

			socket.emit('checkClientPassword', password, (isPasswordValid) => {
				if (!isPasswordValid) {
					alert('Sorry, that password is invalid.');
					setUserState(userStates.INIT);
				}

				setClientPasswordViaRef(password);
			});
		});

		socket.on('clearChat', () => {
			setMessages([]);
		});
	}, []);

	if (userState === userStates.INIT) {
		return 'Loading...';
	}

	return (
		<main>
			<header>
				<img className="icon" src={SkullIcon} />
				<span>Pirate Cast TV</span>
				<UsernameSettings self={self} onClick={() => setUserState(userStates.SET_NAME)} />
			</header>
			<div className="container">
				<Video selfId={selfIdRef.current} isLive={isLiveRef.current} streamTitle={streamTitleRef.current} streamURL={streamURLRef.current} users={users} />
				<LiveChat usersByName={usersByName} users={users} messages={messages} onAddMessage={onAddUserMessage} />
			</div>
			<Modal>{getDialog(userState)}</Modal>
		</main>
	);
}
