import './button.css';

const Button = ({
	isSubmit,
	isSecondary,
	isLink,
	children,
	...buttonProps
}) => {
	return (
		<div
			className={`button${isSecondary ? ' is-secondary' : ''}${
				isLink ? ' is-link' : ''
			}`}
		>
			{isSubmit ? (
				<input type="submit" value={children} {...buttonProps} />
			) : (
				<button {...buttonProps}>{children}</button>
			)}
		</div>
	);
};

export default Button;
