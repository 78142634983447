import './dialog-name.css';

import { useEffect, useState } from 'react';

import Button from './button';
import DialogHeader from './dialog-header';

const DialogName = ({ name, onSetName, onCancel }) => {
	const [userName, setUserName] = useState(name);

	useEffect(() => {
		document.getElementsByClassName('name-input')[0].focus();
		document.getElementsByClassName('name-input')[0].select();
	}, []);

	return (
		<div className="dialog dialog-name">
			<DialogHeader title="Set your profile..." onCancel={onCancel} />
			<form
				className="content dialog-item"
				onSubmit={(event) => {
					event.preventDefault();
					onSetName(userName);
				}}
			>
				<p>
					Ahoy, welcome to Pirate Cast TV! Before you can enter you'll need a
					name to go by.
				</p>
				<div className="name">
					<div>You shall be called:</div>
					<input
						maxLength="40"
						className="name-input"
						type="text"
						placeholder="Input a name..."
						value={userName}
						onChange={(event) =>
							setUserName(event.target.value.replace(/ /g, ''))
						}
					/>
					<span>(You can change it later)</span>
				</div>
				<div className="buttons">
					<Button isSubmit className="main-button">
						Use this name
					</Button>
				</div>
			</form>
		</div>
	);
};

export default DialogName;
