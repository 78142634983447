export const userColors = [
	'#ff0068',
	'#c400ff',
	'#4f00ff',
	'#0128ff',
	'#00a8ff',
	'#00ffd4',
	'#00ff2f',
	'#d4ff00',
	'#ff8601',
	'#ff0008',
];
