import { commands } from '../constants/commands';

const commandStrings = Object.values(commands);

export const messageTypes = {
	MESSAGE_TYPE_TEXT: 'text',
	MESSAGE_TYPE_COMMAND: 'command',
	MESSAGE_TYPE_UNRECOGNIZED: 'unrecognized',
};

export const parseMessage = (text) => {
	if (text.startsWith('/')) {
		const command = commandStrings.find((c) => text.indexOf(`/${c}`) === 0);

		if (command) {
			return {
				type: messageTypes.MESSAGE_TYPE_COMMAND,
				command,
				commandText: text.substr(command.length + 1),
			};
		}

		return {
			type: messageTypes.MESSAGE_TYPE_UNRECOGNIZED,
			text,
		};
	}

	return {
		type: messageTypes.MESSAGE_TYPE_TEXT,
		text,
	};
};
