import './modal.css';

const Modal = ({ children }) => {
	if (!children) {
		return null;
	}

	return <div className="modal modal-background">{children}</div>;
};

export default Modal;
