import './live-chat.css';

import { useState, useEffect } from 'react';

import Button from '../components/button.js';
import Linkify from 'linkify-react';
import Userify from '../components/userify.js';
import UserName from '../components/user-name.js';

const LiveChat = ({ users, usersByName, messages, onAddMessage }) => {
	const [nextMessage, setNextMessage] = useState('');

	const onSubmit = (event) => {
		event.preventDefault();

		onAddMessage(nextMessage);
		setNextMessage('');
	};

	useEffect(() => {
		const chatHistoryEl = document.getElementsByClassName('chat-history')[0];
		chatHistoryEl.scrollTop = chatHistoryEl.scrollHeight;
	}, [messages]);

	return (
		<div className="live-chat">
			<ul className="chat-history">
				{messages.length === 0 ? <li className="placeholder">Chat's empty. Send a message below!</li> : null}
				{messages.map(({ id, type, payload }) => {
					switch (type) {
						case 'system:joined':
							return (
								<li key={id}>
									👋 <UserName user={users[payload.userId]} name={payload.name} /> joined!
								</li>
							);

						case 'system:update':
							if (payload.prop !== 'name') {
								return;
							}

							return (
								<li key={id}>
									👁️ <UserName user={users[payload.userId]} name={payload.oldValue} /> changed their name to{' '}
									<UserName user={users[payload.userId]} name={payload.newValue} />
								</li>
							);

						case 'system:left':
							return (
								<li key={id}>
									🚪 <UserName user={users[payload.userId]} name={payload.name} />
									left
								</li>
							);

						case 'system:notification':
							return <li key={id}>{payload.text} (Only you can see this message)</li>;

						case 'user':
							return (
								<li key={id}>
									<UserName user={users[payload.userId]} name={payload.name} />
									<Linkify options={{ target: '_blank' }}>
										<Userify usersByName={usersByName}>{payload.text}</Userify>
									</Linkify>
								</li>
							);

						default:
							return null;
					}
				})}
			</ul>
			<form className="message-form" onSubmit={onSubmit}>
				<input
					type="text"
					className="new-message"
					placeholder="Send a message..."
					value={nextMessage}
					maxLength="500"
					onChange={(event) => setNextMessage(event.target.value)}
				/>
				<Button
					isSubmit
					onClick={() => {
						document.getElementsByClassName('new-message')[0].focus();
					}}
				>
					Send
				</Button>
			</form>
		</div>
	);
};

export default LiveChat;
